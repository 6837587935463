<template>
  <div class="collection-detail-page">
    <div class="collection-detail-page-back">
      <i class="arrow left" @click="backToSpeContract()"></i>
    </div>
    <div class="main-section">
      <span
        class="collection-title g--font-size-7 g--font-size-8@sm g--font-size-9@md g--font-size-10@lg"
        >{{ $t(collectionTitleShown()) }}</span
      >
      <div
        class="underlined-separator g--width-5 g--width-6@sm g--width-6@md g--width-7@lg g--borderBottom-1 g--borderBottom-2@sm g--borderBottom-3@md g--borderBottom-4@lg"
      ></div>
    </div>
    <div class="g-wrapper">
      <div class="collection-gallery">
        <div
          v-for="(image, i) in data[id.contractCollectionId].images"
          :key="i"
          class="collection-gallery__container"
          @click="index = i"
        >
          <img
            class="carousel__loader"
            src="https://www.mueblescercos.com/images/loader.gif"
            alt=""
          />
          <img
            @load="ImageLoaded($event)"
            :src="image"
            class="collection-gallery__container__image"
            alt=""
          />
        </div>
      </div>
    </div>

    <vue-gallery-slideshow
      :images="data[id.contractCollectionId].images"
      :index="index"
      @close="index = null"
    ></vue-gallery-slideshow>
  </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";
export default {
  components: {
    VueGallerySlideshow,
  },

  data() {
    return {
      id: this.$route.params,
      index: null,
      data: [
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/4.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/5.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/6.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/7.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/8.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/10.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/11.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/12.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/13.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/14.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/15.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/16.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/17.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PORTO_PONIENTE/18.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/4.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/5.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/6.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/7.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/8.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/9.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/10.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/11.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/12.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/13.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_BLUE_MAR_BOUTIQUE/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_BLUE_MAR_BOUTIQUE/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_BLUE_MAR_BOUTIQUE/3.png",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_BLUE_MAR_BOUTIQUE/4.png",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DE_LA_PLATA/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DE_LA_PLATA/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DE_LA_PLATA/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DE_LA_PLATA/4.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DE_LA_PLATA/5.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DE_LA_PLATA/6.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DE_LA_PLATA/7.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DE_LA_PLATA/8.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DE_LA_PLATA/9.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DEL_MAR/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DEL_MAR/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DEL_MAR/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DEL_MAR/4.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DEL_MAR/5.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DEL_MAR/6.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DEL_MAR/7.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DEL_MAR/8.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CASA_DEL_MAR/10.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CUBIK/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CUBIK/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CUBIK/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CUBIK/4.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CUBIK/5.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_CUBIK/6.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_MARINA/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_MARINA/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_MARINA/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_MARINA/4.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_MARINA/5.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PUNTA_NEGRA/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PUNTA_NEGRA/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_PUNTA_NEGRA/3.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_THE_ONE/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_THE_ONE/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_THE_ONE/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_H10_THE_ONE/4.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/APARTAHOTEL_SPA_ESQUIROL/1.jpg",
            "https://www.mueblescercos.com/images/contract/APARTAHOTEL_SPA_ESQUIROL/2.jpg",
            "https://www.mueblescercos.com/images/contract/APARTAHOTEL_SPA_ESQUIROL/3.jpg",
            "https://www.mueblescercos.com/images/contract/APARTAHOTEL_SPA_ESQUIROL/4.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/BATTOYOR_HOLDING_TOWER_EN_AL_KNOBAR/1.jpg",
            "https://www.mueblescercos.com/images/contract/BATTOYOR_HOLDING_TOWER_EN_AL_KNOBAR/2.jpg",
            "https://www.mueblescercos.com/images/contract/BATTOYOR_HOLDING_TOWER_EN_AL_KNOBAR/3.jpg",
            "https://www.mueblescercos.com/images/contract/BATTOYOR_HOLDING_TOWER_EN_AL_KNOBAR/4.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/CASINO_GRAN_VIA_MADRID/1.png",
            "https://www.mueblescercos.com/images/contract/CASINO_GRAN_VIA_MADRID/6.jpg",
            "https://www.mueblescercos.com/images/contract/CASINO_GRAN_VIA_MADRID/2.png",
            "https://www.mueblescercos.com/images/contract/CASINO_GRAN_VIA_MADRID/3.png",
            "https://www.mueblescercos.com/images/contract/CASINO_GRAN_VIA_MADRID/4.png",
            "https://www.mueblescercos.com/images/contract/CASINO_GRAN_VIA_MADRID/5.png",
            "https://www.mueblescercos.com/images/contract/CASINO_GRAN_VIA_MADRID/7.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_AUBERGE_DU_MOULIN/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_AUBERGE_DU_MOULIN/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_AUBERGE_DU_MOULIN/3.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_MARRIOTT_ASTANA/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_MARRIOTT_ASTANA/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_MARRIOTT_ASTANA/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_MARRIOTT_ASTANA/4.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_MARRIOTT_ASTANA/5.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_MARRIOTT_ASTANA/6.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_PRINCESA_PARC/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PRINCESA_PARC/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PRINCESA_PARC/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PRINCESA_PARC/4.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_PULLMAN/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PULLMAN/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PULLMAN/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PULLMAN/4.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PULLMAN/5.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PULLMAN/6.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PULLMAN/7.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PULLMAN/8.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_PULLMAN/9.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/VILLA_RESIDENCIA/1.jpg",
            "https://www.mueblescercos.com/images/contract/VILLA_RESIDENCIA/2.jpg",
            "https://www.mueblescercos.com/images/contract/VILLA_RESIDENCIA/3.jpg",
            "https://www.mueblescercos.com/images/contract/VILLA_RESIDENCIA/4.jpg",
            "https://www.mueblescercos.com/images/contract/VILLA_RESIDENCIA/5.jpg",
            "https://www.mueblescercos.com/images/contract/VILLA_RESIDENCIA/6.jpg",
            "https://www.mueblescercos.com/images/contract/VILLA_RESIDENCIA/7.jpg",
          ],
        },
        {
          images: [
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/1.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/2.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/3.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/4.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/5.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/6.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/7.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/8.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/9.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/10.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/11.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/12.jpg",
            "https://www.mueblescercos.com/images/contract/HOTEL_COSTA_CALERO/13.jpg",
          ],
        },
      ],
    };
  },
  methods: {
    ImageLoaded(e) {
      if (e.target == null) {
        var element = e.path[0];
      } else {
        var element = e.target;
      }

      setTimeout(() => {
        this.Loading = false;
        setTimeout(() => {
          element.style.pointerEvents = "visible";
          element.style.opacity = 1;
        }, 400);
      }, 1000);
    },
    backToSpeContract() {
      this.$router.push("/contract");
      setTimeout(() => {
        this.$root.$emit("ref", this.id.contractCollectionId);
      }, 500);
    },
    collectionTitleShown() {
      var title = "";
      switch (this.id.contractCollectionId) {
        case "0":
          title = "contract_page.contract_hotels.H10PP";
          break;
        case "1":
          title = "contract_page.contract_hotels.CCTS";
          break;
        case "2":
          title = "contract_page.contract_hotels.H10BMBH";
          break;
        case "3":
          title = "contract_page.contract_hotels.H10CDLPH";
          break;
        case "4":
          title = "contract_page.contract_hotels.H10CDMH";
          break;
        case "5":
          title = "contract_page.contract_hotels.CH";
          break;
        case "6":
          title = "contract_page.contract_hotels.H10MH";
          break;
        case "7":
          title = "contract_page.contract_hotels.H10PNH";
          break;
        case "8":
          title = "contract_page.contract_hotels.H10O";
          break;
        case "9":
          title = "contract_page.contract_hotels.EAS";
          break;
        case "10":
          title = "contract_page.contract_hotels.BHT";
          break;
        case "11":
          title = "contract_page.contract_hotels.CCVM";
          break;
        case "12":
          title = "contract_page.contract_hotels.ADMH";
          break;
        case "13":
          title = "contract_page.contract_hotels.MAH";
          break;
        case "14":
          title = "contract_page.contract_hotels.PPH";
          break;
        case "15":
          title = "contract_page.contract_hotels.PBSH";
          break;
        case "16":
          title = "contract_page.contract_hotels.RV";
          break;
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.collection-detail-page {
  margin-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .collection-detail-page-back {
    position: absolute;
    top: 130px;
    left: 100px;
    width: 50px;
    height: 50px;
    @media (max-width: 1300px) {
      left: 5%;
    }
    @media (min-width: 1650px) {
      left: 10%;
    }
    @media (min-width: 2300px) {
      left: 20%;
    }
    @media (max-width: 700px) {
      display: none;
    }
  }
  .arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 10px;
    transition: 0.4s all ease-in-out;
    &:hover {
      border: solid #e9be35;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 10px;
    }
  }
  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .main-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .collection-title {
      font-weight: bold;
    }

    .collection-description {
      text-align: center;
      font-weight: bold;
    }

    .underlined-separator {
      margin-top: 30px;
    }
  }

  .collection-gallery {
    opacity: 1;
    --gap: 1em;
    --columns: 3;
    max-width: 100%;
    margin: 0 auto;
    columns: var(--columns);
    gap: var(--gap);
    & > * {
      break-inside: avoid;
      margin-bottom: var(--gap);
    }
    @supports (grid-template-rows: masonry) {
      display: grid;
      grid-template-columns: repeat(var(--columns), 1fr);
      grid-template-rows: masonry;
      grid-auto-flow: dense;
      & > * {
        margin-bottom: 0em;
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      overflow: hidden;
      opacity: 1;
      transform: scale(1);
      transition: 0.35s ease-in-out all;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      &__image {
        pointer-events: none;
        opacity: 0;
        cursor: pointer;
        width: 100% !important;
        height: auto !important;
        object-fit: cover;
        transition: 0.35s ease-in-out all;
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        overflow: hidden;
        &:hover {
          transform: scale(1.1);
          -webkit-filter: brightness(65%);
        }
      }
    }
  }
}
</style>
