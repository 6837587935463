<template>
  <lazy-component wrapper-tag="section" class="statistics-container">
    <div class="stat-section">
      <span
        class="stat-title g--font-size-7 g--font-size-6@sm g--font-size-7@md g--font-size-7@lg"
        >{{ $t("home_page.stats_component.present") }}</span
      >
      <div class="info-container">
        <span
          class="stat-data g--font-size-21 g--font-size-20@sm g--font-size-21@md g--font-size-23@lg"
          >+14</span
        >
        <span class="stat-info">{{
          $t("home_page.stats_component.countries")
        }}</span>
      </div>
    </div>
    <div class="stat-section">
      <span
        class="stat-title g--font-size-7 g--font-size-6@sm g--font-size-7@md g--font-size-7@lg"
        >{{ $t("home_page.stats_component.experience") }}</span
      >
      <div class="info-container">
        <span
          class="stat-data g--font-size-21 g--font-size-20@sm g--font-size-21@md g--font-size-23@lg"
          >+50</span
        >
        <span class="stat-info">{{
          $t("home_page.stats_component.years")
        }}</span>
      </div>
    </div>
    <div class="stat-section">
      <span
        class="stat-title g--font-size-7 g--font-size-6@sm g--font-size-7@md g--font-size-7@lg"
        >{{ $t("home_page.stats_component.projects") }}</span
      >
      <div class="info-container">
        <span
          class="stat-data g--font-size-21 g--font-size-20@sm g--font-size-21@md g--font-size-23@lg"
          >+300</span
        >
      </div>
    </div>
    <div class="stat-section">
      <span
        class="stat-title g--font-size-7 g--font-size-6@sm g--font-size-7@md g--font-size-7@lg"
        >{{ $t("home_page.stats_component.warranty") }}</span
      >
      <div class="info-container">
        <span
          class="stat-data g--font-size-21 g--font-size-20@sm g--font-size-21@md g--font-size-23@lg"
          >100%</span
        >
      </div>
    </div>
  </lazy-component>
</template>

<script>
import LazyComponent from "v-lazy-component";

export default {
  name: "StatsComponent",
  components: {
    LazyComponent,
  },
};
</script>

<style lang="scss">
.statistics-container {
  width: 100%;
  display: flex;

  .stat-section {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .stat-title {
      font-weight: bold;
      color: #b38a0e;
    }

    .info-container {
      display: flex;
      margin-top: 10px;
      align-items: flex-end;

      .stat-data {
        font-weight: bold;
        color: #b38a0e;
      }

      .stat-info {
        font-weight: bold;
        color: #b38a0e;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .statistics-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .stat-section {
      width: 80%;
      margin-top: 20px;
      padding: 20px;
    }
  }
}
</style>
