<template>
  <lazy-component wrapper-tag="section" class="services-container">
    <h2
      class="services-title g--font-size-7 g--font-size-8@sm g--font-size-9@md g--font-size-10@lg"
    >
      {{ $t("home_page.services_component.title") }}
    </h2>
    <div
      class="services-title-underline g--width-5 g--width-6@sm g--width-6@md g--width-7@lg g--borderBottom-1 g--borderBottom-2@sm g--borderBottom-3@md g--borderBottom-4@lg"
    ></div>
    <div class="sections-container">
      <div class="service-section" v-on:click="selectIndex(0)">
        <img
          class="service-section-image g--width-6 g--width-6@sm g--width-6@md g--width-7@lg"
          src="https://www.mueblescercos.com/images/services/technicalDev.png"
          alt=""
        />
        <span
          class="section-title g--font-size-6 g--font-size-6@sm g--font-size-7@md g--font-size-7@lg"
          >{{ $t(data[0].title) }}</span
        >
        <div class="triangle" v-if="this.index == 0"></div>
      </div>
      <div class="service-section" v-on:click="selectIndex(1)">
        <img
          class="service-section-image g--width-6 g--width-6@sm g--width-6@md g--width-7@lg"
          src="https://www.mueblescercos.com/images/services/fabrication.png"
          alt=""
        />
        <span
          class="section-title g--font-size-6 g--font-size-6@sm g--font-size-7@md g--font-size-7@lg"
          >{{ $t(data[1].title) }}</span
        >
        <div class="triangle" v-if="this.index == 1"></div>
      </div>
      <div class="service-section" v-on:click="selectIndex(2)">
        <img
          class="service-section-image g--width-6 g--width-6@sm g--width-6@md g--width-7@lg"
          src="https://www.mueblescercos.com/images/services/execution.png"
          alt=""
        />
        <span
          class="section-title g--font-size-6 g--font-size-6@sm g--font-size-7@md g--font-size-7@lg"
          >{{ $t(data[2].title) }}</span
        >
        <div class="triangle" v-if="this.index == 2"></div>
      </div>
      <div class="service-section" v-on:click="selectIndex(3)">
        <img
          class="service-section-image g--width-6 g--width-6@sm g--width-6@md g--width-7@lg"
          src="https://www.mueblescercos.com/images/services/materialIntegration.png"
          alt=""
        />
        <span
          class="section-title g--font-size-6 g--font-size-6@sm g--font-size-7@md g--font-size-7@lg"
          >{{ $t(data[3].title) }}</span
        >
        <div class="triangle" v-if="this.index == 3"></div>
      </div>
    </div>
    <div class="description-container">
      <span
        class="description-title g--font-size-7 g--font-size-7@sm g--font-size-8@md g--font-size-8@lg"
        >{{ $t(data[index].title) }}</span
      >
      <div
        class="title-underline g--width-10 g--width-20@sm g--width-20@md g--width-20@lg g--borderBottom-1 g--borderBottom-1@sm g--borderBottom-2@md g--borderBottom-2@lg"
      ></div>
      <span
        class="description-text g--font-size-5 g--font-size-5@sm g--font-size-6@md g--font-size-7@lg"
        >{{ $t(data[index].description) }}</span
      >
    </div>
  </lazy-component>
</template>

<script>
import LazyComponent from "v-lazy-component";

export default {
  components: {
    LazyComponent,
  },
  data() {
    return {
      index: 0,
      data: [
        {
          title: "home_page.services_component.section_1.title",
          description: "home_page.services_component.section_1.description",
        },
        {
          title: "home_page.services_component.section_2.title",
          description: "home_page.services_component.section_2.description",
        },
        {
          title: "home_page.services_component.section_3.title",
          description: "home_page.services_component.section_3.description",
        },
        {
          title: "home_page.services_component.section_4.title",
          description: "home_page.services_component.section_4.description",
        },
      ],
    };
  },
  methods: {
    selectIndex(newIndex) {
      this.index = newIndex;
    },
  },
};
</script>

<style lang="scss">
.services-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 20px 15px 40px 15px;
  width: 100%;
  .services-title {
    color: black;
    font-weight: bold;
  }
  .services-title-underline {
    margin-top: 10px;
  }
  .sections-container {
    display: flex;
    width: 100%;
    margin-top: 25px;
    .service-section {
      -webkit-tap-highlight-color: transparent;
      width: 25%;
      min-height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #eae4da;
      margin: 5px;
      padding: 5px;
      cursor: pointer;
      position: relative;
      transition: 0.5s background-color;
      .service-section-image {
        margin-bottom: 10px;
      }
      .section-title {
        font-weight: bold;
        color: #b38a21;
        text-align: center;
      }
    }
    .service-section:hover {
      background-color: #d4c7af;
    }
    .triangle {
      content: "";
      width: 0px;
      height: 0px;
      position: absolute;
      border-style: solid;
      border-width: 15px 15px 0 15px;
      border-color: #eae4da transparent transparent transparent;
      left: 46%;
      bottom: -25px;
    }
  }
  @media screen and (max-width: 500px) {
    .sections-container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .triangle {
        display: none;
      }
      .service-section {
        width: 80%;
      }
    }
  }
  .description-container {
    margin-top: 30px;
    .description-title {
      font-weight: 900;
    }
    .title-underline {
      margin-top: 5px;
      margin-bottom: 8px;
    }
    .description-text {
      font-weight: 600;
    }
  }
}
</style>
