<template>
  <div class="v-home">
    <ContractComponent />
    <div class="v-home__separator"></div>
    <ServicesComponent />
    <div class="v-home__separator"></div>
    <ClientsComponent />
    <div class="v-home__separator v-home__separator__extra"></div>
    <StatsComponent />
  </div>
</template>

<script>
import ContractComponent from "@/components/ContractComponent.vue";
import ServicesComponent from "@/components/ServicesComponent.vue";
import StatsComponent from "@/components/StatsComponent.vue";
import ClientsComponent from "@/components/ClientsComponent.vue";
import LazyComponent from "v-lazy-component";

export default {
  name: "Home",
  components: {
    ContractComponent,
    ServicesComponent,
    ClientsComponent,
    StatsComponent,
    LazyComponent,
  },
};
</script>

<style lang="scss">
.v-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  &__separator {
    height: 70px;
    &__extra {
      margin-top: 70px;
    }
  }
}
</style>
