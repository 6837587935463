import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Carpentry from '@/views/Carpentry.vue'
import Furniture from '@/views/Furniture.vue'
import FurnitureCollection from '@/views/FurnitureCollection.vue'
import ContractCollection from '@/views/ContractCollection.vue'
import Contact from '@/views/Contact.vue'



Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: "/home"
  },
  {
    path: ' ',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/contract',
    name: 'Contract',
    // route level code-splitting
    // this generates a separate chunk (contract.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contract" */ '../views/Contract.vue')
  },
  {
    path: '/carpentry',
    name: 'Carpentry',
    // route level code-splitting
    // this generates a separate chunk (carpentry.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "carpentry" */ '../views/Carpentry.vue')
  },
  {
    path: '/furniture',
    name: 'Furniture',
    // route level code-splitting
    // this generates a separate chunk (furniture.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "furniture" */ '../views/Furniture.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/contractCollection/:contractCollectionId',
    name: 'contractCollection',
    component: ContractCollection
  },
  {
    path: '/collection/:collectionId',
    name: 'collection',
    component: FurnitureCollection
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(){
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
})

export default router
