<template>
  <div>
    <img
      class="carousel__loader"
      src="https://www.mueblescercos.com/images/loader.gif"
      alt=""
    />
    <carousel
      @pageChange="pageChange"
      class="carousel"
      :per-page="1"
      :autoplay="true"
      :mouse-drag="false"
      :loop="true"
      :paginationEnabled="false"
      :autoplayTimeout="this.autoplayTimeout"
      :autoplayHoverPause="false"
      :speed="1400"
      :adjustableHeight="true"
      :value="this.Pos"
    >
      <slide
        class="carousel__slide"
        v-for="(Image, index) in data[position].images"
        :key="index"
      >
        <lazy-component wrapper-tag="section">
          <img
            @load="ContentLoad($event)"
            v-rellax="{
              speed: 3,
            }"
            class="carousel__slide__image blurred"
            :src="Image"
            alt=""
          />
          <span slot="placeholder">
            <img
              v-rellax="{
                speed: 3,
              }"
              class="carousel__slide__image__reduced blurred"
              :src="Image"
              alt=""
            />
          </span>
        </lazy-component>
      </slide>
    </carousel>
    <div class="carousel__arrow">
      <div class="carousel__arrow__item"></div>
    </div>
    <div
      class="carousel__arrow carousel__arrow--second"
      v-on:click="$emit('animateTo', 'content')"
    >
      <div class="carousel__arrow__item carousel__arrow__item--second"></div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import Vue from "vue";
import VueRellax from "vue-rellax";
import LazyComponent from "v-lazy-component";

Vue.use(VueRellax);
export default {
  props: {
    Data: Array,
  },
  data() {
    return {
      autoplayTimeout: 100,
      Pos: 2,
      FirstRun: false,
      position: 0,
      data: [
        {
          images: [
            "https://www.mueblescercos.com/images/carousel/0.jpg",
            "https://www.mueblescercos.com/images/carousel/1.jpg",
            "https://www.mueblescercos.com/images/carousel/2.jpg",
            "https://www.mueblescercos.com/images/carousel/3.jpg",
            "https://www.mueblescercos.com/images/carousel/4.jpg",
            "https://www.mueblescercos.com/images/carousel/6.jpg",
            "https://www.mueblescercos.com/images/carousel/7.jpg",
            "https://www.mueblescercos.com/images/carousel/8.jpg",
            "https://www.mueblescercos.com/images/carousel/9.jpg",
            "https://www.mueblescercos.com/images/carousel/10.jpg",
            "https://www.mueblescercos.com/images/carousel/11.jpg",
            "https://www.mueblescercos.com/images/carousel/12.jpg",
            "https://www.mueblescercos.com/images/carousel/13.jpg",
            "https://www.mueblescercos.com/images/carousel/14.jpg",
            "https://www.mueblescercos.com/images/carousel/15.jpg",
            "https://www.mueblescercos.com/images/carousel/16.jpg",
            "https://www.mueblescercos.com/images/carousel/17.jpg",
            "https://www.mueblescercos.com/images/carousel/18.jpg",
            "https://www.mueblescercos.com/images/carousel/19.jpg",
            "https://www.mueblescercos.com/images/carousel/20.jpg",
            "https://www.mueblescercos.com/images/carousel/21.jpg",
            "https://www.mueblescercos.com/images/carousel/22.jpg",
            "https://www.mueblescercos.com/images/carousel/23.jpg",
          ],
        },
        {
          images: [
            //CARPENTRY
            "https://www.mueblescercos.com/images/carpentry/armarios/1.jpg",
            "https://www.mueblescercos.com/images/carpentry/doors/4.jpg",
            "https://www.mueblescercos.com/images/carpentry/walls/3.jpg",
            "https://www.mueblescercos.com/images/carpentry/armarios/3.jpg",
            "https://www.mueblescercos.com/images/carpentry/doors/5.jpg",
            "https://www.mueblescercos.com/images/carpentry/walls/4.jpg",
            "https://www.mueblescercos.com/images/carpentry/armarios/4.jpg",
            "https://www.mueblescercos.com/images/carpentry/doors/8.jpg",
            "https://www.mueblescercos.com/images/carpentry/walls/5.jpg",
            "https://www.mueblescercos.com/images/carpentry/armarios/5.jpg",
            "https://www.mueblescercos.com/images/carpentry/walls/6.jpg",
            "https://www.mueblescercos.com/images/carpentry/armarios/6.jpg",
          ],
        },
        {
          images: [
            //FURNITURE
            "https://www.mueblescercos.com/images/furniture/classic/COLECCION_MILAN/7.jpg",
            "https://www.mueblescercos.com/images/furniture/classic/COLECCION_MILAN/8.jpg",
            "https://www.mueblescercos.com/images/furniture/classic/COLECCION_MILAN/12.jpg",
            "https://www.mueblescercos.com/images/furniture/classic/COLECCION_SAMARA/2.png",
            "https://www.mueblescercos.com/images/furniture/classic/COLECCION_SAMARA/3.png",
            "https://www.mueblescercos.com/images/furniture/classic/COLECCION_SAMARA/4.png",
            "https://www.mueblescercos.com/images/furniture/classic/COLECCION_VALENCIA/4.png",
            "https://www.mueblescercos.com/images/furniture/classic/COLECCION_VALENCIA/1.png",
            "https://www.mueblescercos.com/images/furniture/classic/COLECCION_FABULA/1.jpg",
            "https://www.mueblescercos.com/images/furniture/contemporary/COLECCION_SFERA/2.jpg",
            "https://www.mueblescercos.com/images/furniture/contemporary/COLECCION_SFERA/7.jpg",
            "https://www.mueblescercos.com/images/furniture/contemporary/COLECCION_SFERA/8.jpg",
          ],
        },
      ],
    };
  },
  components: {
    Carousel,
    Slide,
    LazyComponent,
  },
  mounted() {
    this.value = 0;
    var actualUrl = window.location.href;
    if (actualUrl.includes("carpentry")) {
      this.position = 1;
    } else if (actualUrl.includes("furniture")) {
      this.position = 2;
    } else {
      this.position = 0;
    }

    this.$root.$on("moveTo", (pos) => {
      this.animateCarousel();
      setTimeout(() => {
        this.position = pos;
        this.value = 0;
      }, 550);
    });
  },
  methods: {
    animateCarousel() {
      var carousel = document.getElementById("carousel");
      carousel.style.opacity = 0;
      setTimeout(() => {
        carousel.style.opacity = 1;
      }, 1000);
    },
    ContentLoad(e) {
      if (e.target == null || e.target == undefined) {
        var element = e.path[0];
      } else {
        var element = e.target;
      }
      setTimeout(() => {
        document.getElementsByClassName("carousel")[0].style.opacity = 1;
        setTimeout(() => {
          element.classList.remove("blurred");
        }, 350);
      }, 350);
    },
    pageChange() {
      if (this.FirstRun == false) {
        this.autoplayTimeout = 6000;
        this.Pos = 0;
        this.FirstRun = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  opacity: 0;
  height: 100vh;
  min-height: 100vh;
  transition: 0.35s ease-in-out opacity;
  -webkit-transition: 0.35s ease-in-out opacity;
  -moz-transition: 0.35s ease-in-out opacity;
  -ms-transition: 0.35s ease-in-out opacity;
  -o-transition: 0.35s ease-in-out opacity;
  &__slide {
    height: 100vh;
    min-height: 100vh;
    &__image {
      margin-top: -200px;
      height: 140vh;
      min-height: 140vh;
      width: 100%;
      object-fit: cover;
      filter: blur(0px);
      transition: 0.35s ease-in-out filter;
      -webkit-transition: 0.35s ease-in-out filter;
      -moz-transition: 0.35s ease-in-out filter;
      -ms-transition: 0.35s ease-in-out filter;
      -o-transition: 0.35s ease-in-out filter;
      &__reduced {
        &.blurred {
          filter: blur(20px);
          transition: 0.35s ease-in-out filter;
          -webkit-transition: 0.35s ease-in-out filter;
          -moz-transition: 0.35s ease-in-out filter;
          -ms-transition: 0.35s ease-in-out filter;
          -o-transition: 0.35s ease-in-out filter;
        }
      }
      &.blurred {
        filter: blur(20px);
        transition: 0.35s ease-in-out filter;
        -webkit-transition: 0.35s ease-in-out filter;
        -moz-transition: 0.35s ease-in-out filter;
        -ms-transition: 0.35s ease-in-out filter;
        -o-transition: 0.35s ease-in-out filter;
      }
    }
  }
  &__arrow {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 63px;
    bottom: 15px;
    &__item {
      position: relative;
      display: inline-block;
      margin: 15px;
      padding: 15px;
      box-shadow: 3px -3px 0 0 rgb(255, 255, 255) inset;
      transform: rotate(-45deg);
      bottom: 15px;
      animation: MoveUpDown 2s linear infinite;
      &--second {
        animation: MoveUpDown2 2s linear infinite;
      }
    }
  }
  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 27px;
  }
}
@keyframes MoveUpDown2 {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 10px;
  }
}

.v-lazy-component.v-lazy-component--loading {
  filter: blur(20px);
  transition: 0.35s ease-in-out opacity;
  -webkit-transition: 0.35s ease-in-out opacity;
  -moz-transition: 0.35s ease-in-out opacity;
  -ms-transition: 0.35s ease-in-out opacity;
  -o-transition: 0.35s ease-in-out opacity;
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: 0.35s ease-in-out opacity;
  -webkit-transition: 0.35s ease-in-out opacity;
  -moz-transition: 0.35s ease-in-out opacity;
  -ms-transition: 0.35s ease-in-out opacity;
  -o-transition: 0.35s ease-in-out opacity;
}
</style>
