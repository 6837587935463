<template>
  <div
    class="
      c-goup
      g--width-5
      g--width-3@sm
      g--width-5@md
      g--width-6@lg
      g--height-5
      g--height-3@sm
      g--height-5@md
      g--height-6@lg
    "
    id="goup"
    v-on:click="$emit('animateTo', 'start')"
  >
    <div class="c-goup__container">
      <div class="c-goup__container__arrow"></div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  mounted() {
    this.scrollFunctionOnStart();
    window.addEventListener("scroll", this.scrollFunction);
    window.addEventListener("scroll", this.scrollFunctionMax);
  },
  methods: {
    scrollFunctionOnStart() {
      var goup = document.getElementById("goup");
      goup.style.bottom = "40px";
    },
    scrollFunction() {
      var goup = document.getElementById("goup");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        goup.style.opacity = 1;
        goup.style.transition = "0.4s ease-in-out";
      } else {
        goup.style.opacity = 0;
      }
    },
    scrollFunctionMax() {
      var goup = document.getElementById("goup");
      var footerHeight = document.getElementById("footer").offsetHeight;

      $(window).scroll(function () {
        if (
          $(window).scrollTop() + $(window).height() ==
          $(document).height()
        ) {
          goup.style.bottom = footerHeight + 30 + "px";
        } else {
          goup.style.bottom = "40px";
        }
      });
    },
  },
};
</script>

<style lang="scss">
.c-goup {
  -webkit-tap-highlight-color: transparent;
  opacity: 0;
  transition: 0.4s ease-in-out;
  position: fixed;
  right: 30px;
  z-index: 99999999;
  border: none;
  outline: none;
  background-color: #e9be35;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 18px;
  @media (max-width: 1120px) {
    right: 15px;
  }
  &:hover {
    background-color: rgb(252, 206, 55);
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    &__arrow {
      position: relative;
      display: inline-block;
      padding: 10px;
      box-shadow: 5px -5px 0 0 #caa62f inset;
      transform: rotate(135deg);
      top: 5px;
      @media (max-width: 1024px) {
        padding: 7px;
        top: 2px;
      }
      @media (max-width: 768px) {
        padding: 5px;
        top: -3px;
      }
      @media (max-width: 640px) {
        padding: 7px;
        top: 2px;
      }
    }
  }
}
</style>