<template>
  <div>
    <AppLoader class="c-app__loader" v-if="Loading" />

    <div class="c-app" ref="start">
      <div class="c-app__header">
        <HeaderComponent @moveToStartNav="moveToStartNav" />
      </div>
      <div
        class="c-app__carousel"
        id="carousel"
        :style="'display:' + hideCarouselOnContact"
      >
        <CarouselComponent @animateTo="animateTo" />
      </div>
      <div class="c-app__ref" ref="content"></div>
      <div class="c-app__main">
        <div class="g-wrapper" ref="content2">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
      <div class="c-app__footer">
        <FooterComponent />
      </div>
      <GoUpComponent @animateTo="animateTo" />
    </div>
  </div>
</template>

<script>
import AppLoader from "@/components/AppLoader.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import CarouselComponent from "@/components/CarouselComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import GoUpComponent from "@/components/GoUpComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    CarouselComponent,
    FooterComponent,
    GoUpComponent,
    AppLoader,
  },
  data() {
    return {
      hideCarouselOnContact: "",
      Loading: true,
    };
  },
  mounted() {
    var start = Date.now();
    var self = this;
    var promise = Promise.resolve(window.location.href);
    promise.then(function (val) {
      self.StartApp(start);
    });
    var url = window.location.href;
    if (
      url.includes("contractCollection") ||
      url.includes("collection") ||
      url.includes("contact")
    ) {
      this.hideCarouselOnContact = "none";
    } else {
      this.hideCarouselOnContact = "";
    }
  },
  watch: {
    $route(to, from) {
      if (to.name == "Furniture") {
        this.$root.$emit("moveToStartNav");
        this.$root.$emit("moveTo", 2);
      }
      if (
        to.name == "Contact" ||
        to.name == "collection" ||
        to.name == "contractCollection"
      ) {
        setTimeout(() => {
          this.hideCarouselOnContact = "none";
        }, 450);
      } else {
        setTimeout(() => {
          this.hideCarouselOnContact = "";
        }, 350);
      }
      if (
        from.name == "Contact" ||
        from.name == "collection" ||
        from.name == "contractCollection"
      ) {
        var carousel = document.getElementById("carousel");
        carousel.style.opacity = 0;
        setTimeout(() => {
          carousel.style.opacity = 1;
        }, 1000);
      }
      if (from.name == "Contract" && to.name == "Furniture") {
        this.animateTo("start");
      }
      if (from.name == "Contract" && to.name == "contractCollection") {
        this.animateTo("start");
      }
      if (from.name == "Furniture" && to.name == "collection") {
        this.animateTo("start");
      }
    },
  },
  methods: {
    StartApp(start) {
      var loader = document.getElementsByClassName("c-app__loader")[0];
      loader.style.opacity = 1;
      var app = document.getElementsByClassName("c-app")[0];
      setTimeout(() => {
        loader.style.opacity = 0;
        setTimeout(() => {
          this.Loading = false;
          app.style.pointerEvents = "visible";
          setTimeout(() => {
            app.style.opacity = "1";
            setTimeout(() => {
              document.getElementsByTagName("html")[0].removeAttribute("style");
              app.style.opacity = 1;
              var end = Date.now();
              console.log(`Tiempo: ${end - start} ms`);
            }, 350);
          }, 350);
        }, 350);
      }, 3000);
    },
    //ANIMATION METHODS
    animateTo(place) {
      if (place == "start") {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      if (place == "content") {
        if (window.innerWidth <= 700) {
          var bottom = this.$refs["content2"].offsetTop - 30;
        } else {
          var bottom = this.$refs["content"].offsetTop;
        }

        window.scrollTo({
          top: bottom,
          behavior: "smooth",
        });
      }
    },
    moveToStartNav() {
      this.animateTo("start");
    },
  },
};
</script>

<style lang="scss">
@import "assets/scss/main.scss";

.c-app {
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  transition: 0.35s ease-in-out all;
  -webkit-transition: 0.35s ease-in-out all;
  -moz-transition: 0.35s ease-in-out all;
  -ms-transition: 0.35s ease-in-out all;
  -o-transition: 0.35s ease-in-out all;
  &__loader {
    opacity: 0;
    transition: 0.35s ease-in-out all;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
  }
  &__header {
    z-index: 999;
  }
  &__ref {
    position: relative;
    top: -123px;
  }
  &__carousel {
    opacity: 1;
    transition: 0.5s ease-in-out opacity;
  }
  &__main {
    min-height: 100vh;
    height: auto;
    padding-top: 30px;
    padding-bottom: 50px;
  }
  &__footer {
    margin-top: auto;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.4s ease-in-out forwards;
  transition-property: opacity;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.hide {
  display: none;
}
</style>
